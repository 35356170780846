import { Container } from "@mui/material";
import { makeStyles } from "@placehires/react-component-library";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import IntroSection from "../components/public/IntroSection";
import VideoWithBackground from "../components/public/VideoWithBackground";
import { noBackgroundWrapper } from "../constants/styles";
import PublicLayout from "../layout/PublicLayout";

const OurStory: React.FC<any> = ({ data }) => {
  const {
    mdx: {
      fields: {
        frontmatter: { videoTitle, videoThumbnail, video }
      }
    },
    headerImage: headerImageRes
  } = data;
  const { classes } = useStyles();
  const headerImage = getImage(headerImageRes);

  return (
    <PublicLayout seoTitle="Our Story">
      <IntroSection title="Our Story" image={headerImage} imageOverlay />
      <Container>
        <div className={classes.bodyWrapper}>
          <VideoWithBackground
            video={{ src: video, title: videoTitle, thumbnail: videoThumbnail }}
          />
        </div>
      </Container>
    </PublicLayout>
  );
};

const useStyles = makeStyles()(() => ({
  bodyWrapper: {
    ...noBackgroundWrapper()
  }
}));

export const ourStoryPageQuery = graphql`
  query OurStoryPage($id: String!) {
    mdx(id: { eq: $id }) {
      fields {
        frontmatter {
          videoTitle
          videoThumbnail
          video
        }
      }
    }

    headerImage: file(relativePath: { eq: "ourStory.jpg" }) {
      ...fluidImage
    }
  }
`;

export default OurStory;
