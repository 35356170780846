import { throttle } from "lodash";
import { SyntheticEvent, useMemo, useState } from "react";
import { VIDEO_EVENT, VIDEO_PROGRESS_EVENTS } from "../constants/analytics";
import { logVideoEvent } from "../utils/utils";

export type VideoEventProps = {
  onPlay: (e: SyntheticEvent<HTMLVideoElement>) => void;
  onEnded: (e: SyntheticEvent<HTMLVideoElement>) => void;
  onPause: (e: SyntheticEvent<HTMLVideoElement>) => void;
  onTimeUpdate: (e: SyntheticEvent<HTMLVideoElement>) => void;
  onDurationChange: (e: SyntheticEvent<HTMLVideoElement>) => void;
};

type ProgressOptions = typeof VIDEO_PROGRESS_EVENTS[number];

type DurationStats = Record<ProgressOptions, [number, number]>;

const initialVideoState = {
  started: false,
  paused: false,
  ended: false,
  ...(() => {
    return VIDEO_PROGRESS_EVENTS.reduce((prev, cur) => {
      prev[cur] = false;
      return prev;
    }, {} as Record<ProgressOptions, boolean>);
  })(),
  progressStats: {} as DurationStats,
  duration: 0
};

/** Hook that returns video event props that tracks the progress of video. */
export const useVideoAnalytics = (videoTitle: string) => {
  const [videoStats, setVideoStats] = useState(initialVideoState);

  const onTimeUpdate = useMemo(
    () =>
      throttle(
        (
          title: string,
          duration: number,
          currentTime: number,
          videoStats: typeof initialVideoState
        ) => {
          VIDEO_PROGRESS_EVENTS.some((progressSection) => {
            const curRange = videoStats.progressStats[progressSection];
            if (!curRange) return true;
            const inRange = currentTime >= curRange[0] && currentTime < curRange[1];
            if (inRange && !videoStats[progressSection]) {
              logVideoEvent(progressSection, { title, duration, currentTime });
              setVideoStats((prevState) => ({
                ...prevState,
                [progressSection]: true
              }));
            }
            return inRange;
          });
        },
        (videoStats.duration * 1000) / 20
      ),
    [videoStats.duration]
  );

  const videoEventProps = useMemo(
    () => ({
      title: videoTitle,
      onPlay: (e: SyntheticEvent<HTMLVideoElement>) => {
        if (!videoStats.started) {
          logVideoEvent(VIDEO_EVENT.START, e);
        } else {
          logVideoEvent(VIDEO_EVENT.RESUME, e);
        }
        setVideoStats({ ...videoStats, started: true, paused: false });
      },
      onEnded: (e: SyntheticEvent<HTMLVideoElement>) => {
        logVideoEvent(VIDEO_EVENT.END, e);
        setVideoStats({ ...videoStats, ended: true });
      },
      onPause: (e: SyntheticEvent<HTMLVideoElement>) => {
        const { duration, currentTime } = e.currentTarget;
        if (duration == currentTime) return;
        logVideoEvent(VIDEO_EVENT.PAUSE, e);
        setVideoStats({ ...videoStats, paused: true });
      },
      onTimeUpdate: (e: SyntheticEvent<HTMLVideoElement>) => {
        const { currentTime, duration, title } = e.currentTarget;
        onTimeUpdate(title, duration, currentTime, videoStats);
      },
      onDurationChange: (e: SyntheticEvent<HTMLVideoElement>) => {
        const { duration } = e.currentTarget;
        const duration10 = duration / 10;
        const progressStats = {} as any;
        for (let i = 1; i < 10; i++) {
          progressStats[`progress${i}0`] = [duration10 * i, duration10 * (i + 1)];
        }
        setVideoStats({
          ...videoStats,
          duration,
          progressStats
        });
      }
    }),
    [videoStats, videoTitle, onTimeUpdate]
  );

  return videoEventProps as VideoEventProps;
};
