import { formatDistanceToNow } from "date-fns";
import { SyntheticEvent } from "react";
import { VIDEO_EVENT_NAME } from "../constants/analytics";
import { analytics } from "../services/firebaseService";

export const separateString = (str: string, separator = ",") => {
  const whiteSpacesRegex = /^\s*$/;
  return str.split(separator).filter((e) => !whiteSpacesRegex.test(e));
};

export const logVideoEvent = (
  eventName: string,
  e:
    | SyntheticEvent<HTMLVideoElement>
    | {
        title: string;
        duration: number;
        currentTime: number;
      }
) => {
  const params = "currentTarget" in e ? e.currentTarget : e;
  const { title, duration, currentTime } = params;
  if (!title) return;
  analytics.logEvent(VIDEO_EVENT_NAME, {
    video_event: eventName,
    video_title: title,
    video_duration: duration,
    video_position: currentTime
  });
};

export const formatDistanceToNowWithoutApproximation = (
  ...args: Parameters<typeof formatDistanceToNow>
) => {
  return formatDistanceToNow(...args).replace(/(about|over|almost|less than) /g, "");
};

/**
 * Returns items sorted by their start, end dates - items with end date further away come first.
 * Original array is not mutated.
 *
 * @param items: Original array to sort
 * @param keys: Keys corresponding to start and end date values. By default, are `startDate` and `endDate`
 */
export const sortByStartEndDates = <
  T extends { [k in StartKey | EndKey]?: Date },
  StartKey extends string = "startDate",
  EndKey extends string = "endDate"
>(
  items: T[],
  keys?: {
    startKey: StartKey;
    endKey: EndKey;
  }
) => {
  if (!items) return [];
  const { startKey, endKey } = keys || {
    startKey: "startDate" as StartKey,
    endKey: "endDate" as EndKey
  };
  const copy = [...items];
  return copy.sort((e1, e2) => {
    if (e1[endKey] === e2[endKey]) {
      if (e1[startKey] > e2[startKey]) return -1;
      else if (e1[startKey] < e2[startKey]) return 1;
      else return 0;
    } else if (e1[endKey] === null) {
      return -1;
    } else if (e2[endKey] === null) {
      return 1;
    } else if (e1[endKey] > e2[endKey]) {
      return -1;
    } else {
      return 1;
    }
  });
};

/** Splits array in 2 at given index */
export const splitArray = <T>(arr: T[], splitIndex: number) => {
  return [arr.slice(0, splitIndex), arr.slice(splitIndex)];
};

/** Converts array to human-readable string that lists items */
export const convertListToReadable = (arr: string[]) => {
  if (!arr || !arr.length) return "";
  if (arr.length === 1) return arr[0];
  return arr.slice(0, arr.length - 1).join(", ") + " and " + arr[arr.length - 1];
};
