import { Box } from "@mui/system";
import React, { useRef } from "react";
import { CARD_BORDER_RADIUS } from "../../gatsby-theme-material-ui-top-layout/theme";
import { useVideoAnalytics } from "../../hooks/analyticsHooks";

type VideoWithBackgroundProps = {
  video: { src: string; title: string; thumbnail: string };
};

const VideoWithBackground: React.FC<VideoWithBackgroundProps> = ({
  video: { src, title, thumbnail }
}) => {
  const videoEventProps = useVideoAnalytics(title);
  const videoRef = useRef<HTMLVideoElement>(null);
  // This is for custom play button commented below. It does not work as expected
  // const isPlaying = videoRef.current ? !videoRef.current.paused : false;

  // const handleVideoButtonClick = () => {
  //   if (videoRef.current) {
  //     if (isPlaying) videoRef.current.pause();
  //     else videoRef.current.play();
  //   }
  // };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        video: {
          borderRadius: CARD_BORDER_RADIUS,
          display: "block"
        },
        ":after": {
          content: "''",
          position: "absolute",
          right: { sm: "-1rem", xs: "-0.5rem" },
          bottom: { sm: "-1rem", xs: "-0.5rem" },
          width: "100%",
          height: "100%",
          bgcolor: "primary.main",
          borderRadius: CARD_BORDER_RADIUS,
          zIndex: -1
        }
      }}
    >
      <video
        ref={videoRef}
        controls
        width="100%"
        preload="metadata"
        poster={thumbnail}
        style={{
          objectFit: "cover"
        }}
        {...videoEventProps}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* Custom play button with overlay */}
      {/*{!isPlaying && (*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      display: "flex",*/}
      {/*      justifyContent: "center",*/}
      {/*      alignItems: "center",*/}
      {/*      position: "absolute",*/}
      {/*      left: 0,*/}
      {/*      top: 0,*/}
      {/*      width: "100%",*/}
      {/*      height: "100%",*/}
      {/*      bgcolor: OVERLAY_BACKGROUND,*/}
      {/*      borderRadius*/}
      {/*    }}*/}
      {/*    onClick={handleVideoButtonClick}*/}
      {/*  >*/}
      {/*    <IconButton*/}
      {/*      size="small"*/}
      {/*      sx={{*/}
      {/*        background: "linear-gradient(87.65deg, #4A148C 4.39%, #78649F 92.68%)",*/}
      {/*        cursor: "pointer"*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <PlayCircleOutline fontSize="large" sx={{ color: "white" }} />*/}
      {/*    </IconButton>*/}
      {/*  </Box>*/}
      {/*)}*/}
    </Box>
  );
};

export default VideoWithBackground;
