export const VIDEO_EVENT_NAME = "video_playback";

export enum VIDEO_EVENT {
  START = "start",
  RESUME = "resume",
  PAUSE = "pause",
  END = "end"
}

export const VIDEO_PROGRESS_EVENTS = [
  "progress10",
  "progress20",
  "progress30",
  "progress40",
  "progress50",
  "progress60",
  "progress70",
  "progress80",
  "progress90"
] as const;
